/* You can add global styles to this file, and also import other style files */
body {
    color: #666;
    padding-top: 90px;
    padding-bottom: 65px;
    margin-bottom: 50px;
}

.cursor-pointer {
    cursor: pointer;
}

.clickable {
    cursor: pointer !important;
    color: #007bff !important;
}

.secondary-nav {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e5e5e5+100;White+3D */
    background: #ffffff; /* Old browsers */
    background: -moz-linear-gradient(top, #e5e5e5 0%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e5e5e5 0%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #e5e5e5 0%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e5e5e5', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    margin-bottom: 10px;
}


/*
    Fix Calendar Button on Date Chooser when form control is small
 */
.btn-sm-date-picker {
    padding: 0 0.375rem !important;
}


/**
    Font Colrs
 */
.font-red {
    color: #cc3300;
}

.font-green{
    color: #00aa00;
}

.font-blue {
    color: #007bff;
}

.font-apple {
    color: #9ca4a7;
}

.font-windows {
    color: #00B0EA
}

.font-linux {
    color: #000;
}

.font-firefox{
    color: #f67b0f;
}

.font-safari {
    color: #42baf6;
}

.font-chrome {
    color: #000;
}

.font-internet-explorer {
    color: #42baf6;
}

.font-android {
    color: #b0dd13;
}


/*
    CARDS
 */
.rs-card {
    border-left: 1px solid #ccc;
    border-right: 1px solid #999;
    border-bottom: 1px solid #999;
    -webkit-box-shadow: 10px 10px 10px -5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 10px 10px 10px -5px rgba(0, 0, 0, 0.25);
    box-shadow: 10px 10px 10px -5px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;
}

.rs-card-blue {
    border-top: 4px solid #007bff;
}

.rs-card-red {
    border-top: 4px solid #cc3300;
}

.rs-card-green {
    border-top: 4px solid #00aa00;
}
.card-header {
    padding: 0.75rem 1.25rem 0 1.25rem;
    margin-bottom: 0;
    background-color: #ffffff;
    border-bottom: none;
}
.rs-card .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: #ffffff;
    border-top: none;
}


/*
    MODALS
 */
.modal-content {
    /*position: relative;*/
    /*display: -ms-flexbox;*/
    /*display: -webkit-box;*/
    /*display: flex;*/
    /*-ms-flex-direction: column;*/
    /*-webkit-box-orient: vertical;*/
    /*-webkit-box-direction: normal;*/
    /*flex-direction: column;*/
    /*background-color: #fff;*/
    /*background-clip: padding-box;*/
    /*border: 1px solid rgba(0, 0, 0, 0.2);*/
    /*border-radius: 0;*/
    /*outline: 0;*/
    border-top: 4px solid #007bff;
    border-left: 1px solid #ccc;
    border-right: 1px solid #999;
    border-bottom: 1px solid #999;
    -webkit-box-shadow: 10px 10px 10px -5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 10px 10px 10px -5px rgba(0, 0, 0, 0.25);
    box-shadow: 10px 10px 10px -5px rgba(0, 0, 0, 0.25);
    margin-bottom: 25px;
}



/*
    TABLES
 */
.rs-table {
    font-size: 12px;
}
.rs-flex-table {
    font-size: 12px;
}
.rs-flex-table .row{
    margin: 0px 5px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-bottom: 1px solid #ddd;
}
.rs-flex-table .row:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}
.rs-flex-table label {
    font-weight: bold;
}

.table-search-results {
    margin-top: 15px;
    border-top: 1px solid #dee2e6;
    color: #333;
    font-size: 0.90em;
}

.table-search-results th {
  color: #666;
  border-bottom: 2px solid #dee2e6;
}

/*
    PAGINATON
 */
.min-paginated-card-height { min-height: 400px; }

/*.pagination-footer {
    display: block;
    position: absolute;
    bottom: 5px;
    left: 15px;
    right: 15px;
    margin: auto;
}*/
.paginated-table-spacer {
    margin-bottom: 40px;
}
/**
    MARGINS & PADDING
 */
.margin-top-15 { margin-top: 15px; }
.margin-top-25 { margin-top: 25px; }
.margin-top-35 { margin-top: 35px; }
.margin-top-50 { margin-top: 50px; }

.padding-top-5 { padding-top: 5px; }
.padding-top-35 { padding-top: 35px; }
.padding-top-50 { padding-top: 50px; }
.padding-10 { padding: 10px; }

.btn-margin {
    margin: 0 5px;
}


/**
  Customer Status Background Colors
 */
.prospect {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#effeff+0,f7fffe+49,effeff+100 */
    background: #effeff; /* Old browsers */
    background: -moz-linear-gradient(top, #effeff 0%, #f7fffe 49%, #effeff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #effeff 0%,#f7fffe 49%,#effeff 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #effeff 0%,#f7fffe 49%,#effeff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#effeff', endColorstr='#effeff',GradientType=0 ); /* IE6-9 */
}
.prospect td {
    border-bottom: 1px solid #94c4cd;
}

.cancelled {

    /*background-color: #F7977A;*/
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fff2fa+0,fff7fc+49,fff2fa+100 */
    background: #fff2fa; /* Old browsers */
    background: -moz-linear-gradient(top, #fff2fa 0%, #fff7fc 49%, #fff2fa 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fff2fa 0%,#fff7fc 49%,#fff2fa 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fff2fa 0%,#fff7fc 49%,#fff2fa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff2fa', endColorstr='#fff2fa',GradientType=0 ); /* IE6-9 */
}
.cancelled td {
    /*border-top: 1px solid #f7515d;*/
    border-bottom: 1px solid #ffb7fe;
}

.escalated {
    /*background-color: #FFF79A;  */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fff8cc+0,fffbe8+27,fffbe8+71,fff8cc+100 */
    background: #fff8cc; /* Old browsers */
    background: -moz-linear-gradient(top, #fff8cc 0%, #fffbe8 27%, #fffbe8 71%, #fff8cc 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #fff8cc 0%,#fffbe8 27%,#fffbe8 71%,#fff8cc 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #fff8cc 0%,#fffbe8 27%,#fffbe8 71%,#fff8cc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff8cc', endColorstr='#fff8cc',GradientType=0 ); /* IE6-9 */
}
.escalated td {
    /*border-top: 1px solid #f7515d;*/
    border-bottom: 1px solid #ffcc66;
}

.chargeback {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e6deed+0,eee8f2+26,eee8f2+68,e6deed+100 */
    background: #e6deed; /* Old browsers */
    background: -moz-linear-gradient(top, #e6deed 0%, #eee8f2 26%, #eee8f2 68%, #e6deed 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #e6deed 0%,#eee8f2 26%,#eee8f2 68%,#e6deed 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #e6deed 0%,#eee8f2 26%,#eee8f2 68%,#e6deed 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6deed', endColorstr='#e6deed',GradientType=0 ); /* IE6-9 */
}
.chargeback td{
    border-bottom: 1px solid #ba94db;
}


.extended-trial {
    background-color: #6ECFF6;
}


.freecredit{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f4ffd3+0,f9ffe8+51,f4ffd3+100 */
    background: #f4ffd3; /* Old browsers */
    background: -moz-linear-gradient(top, #f4ffd3 0%, #f9ffe8 51%, #f4ffd3 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f4ffd3 0%,#f9ffe8 51%,#f4ffd3 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f4ffd3 0%,#f9ffe8 51%,#f4ffd3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4ffd3', endColorstr='#f4ffd3',GradientType=0 ); /* IE6-9 */
}
.freecredit td {
    border-bottom: 1px solid #AACC00;
}

.return-reversal{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f9eede+0,f9f4ed+49,f9eede+100 */
    background: #f9eede; /* Old browsers */
    background: -moz-linear-gradient(top, #f9eede 0%, #f9f4ed 49%, #f9eede 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f9eede 0%,#f9f4ed 49%,#f9eede 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f9eede 0%,#f9f4ed 49%,#f9eede 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9eede', endColorstr='#f9eede',GradientType=0 ); /* IE6-9 */
}
.return-reversal td {
    border-bottom: 1px solid #feb645;
}

.manual-verify {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f6f8f9+0,ffffff+52,f5f7f9+100 */
    background: #f6f8f9; /* Old browsers */
    background: -moz-linear-gradient(top, #f6f8f9 0%, #ffffff 52%, #f5f7f9 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, #f6f8f9 0%,#ffffff 52%,#f5f7f9 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, #f6f8f9 0%,#ffffff 52%,#f5f7f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f8f9', endColorstr='#f5f7f9',GradientType=0 ); /* IE6-9 */
}
.manual-verify td{
    border-bottom: 1px solid #c3c3c3;
}


/**
  Customer Status Fonts
 */
.font-prospect {
    color: #02c1cd;
}
.font-cancelled {
    color: #f7515d;
}
.font-escalated {
    color: #ffb900;
}
.font-chargeback {
    color: #a750db;
}
.font-extended-trial {
    color: #3fa4f6;
}
.font-freecredit, .font-deferred-auth{
    /*color:#DB0D88;*/
    color: #abdd3b;
}
.font-return-reversal{
    color: rgb(254, 109, 25);
}
.font-manual-verify{
    color: rgb(126, 126, 134);
}
.font-thin-file{
	color: rgb(126, 126, 134);
}


.editable-section {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #dedede;
    padding: 5px 0;
    margin-bottom: 10px;
}

.editable-section h3{
    margin: 0;
}


/**
    Override angular2-hotkeys default CSS
 */
.cfp-hotkeys {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: top !important;
    padding-top: 100px;
}
.cfp-hotkeys-close {
    border: none !important;
}


.page-actions button {
  margin-top: 20px;
}

/* Dropdown */
.nav-link i {
  color: #007bff;
}


/* Action Icons */
.page-actions .pa-icon {
  margin-top: 10px;
  margin-right: 10px;
  position: relative;
  height: 25px;
  width: 33px;
}

.page-actions .pa-icon i:first-child,
.add-link-btn .pa-icon i:first-child {
  position: absolute;
  bottom: 0;
  left: 12px;
  bottom: 0;
  color: #999;
}

.page-actions .pa-icon i:nth-child(2),
.add-link-btn .pa-icon i:nth-child(2){
  position: absolute;
  bottom: 0px;
  left: 0;
  color: #007bff;
}

.action-icons i {
  padding: 5px;
}
