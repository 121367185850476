// Followed this guide: https://getbootstrap.com/docs/5.2/customize/sass/#importing

// SCSS variable overrides happen BEFORE bootstrap is loaded

$dropdown-padding-y:0;
$font-size-root: 14px;
//$body-color: #666;

// Includes all of bootstrap
@import "bootstrap/scss/bootstrap";

// CSS variables should be set AFTER bootstrap is loaded

:root {
  --bs-primary-rgb: 0, 123, 255;
  --bs-link-color: #007bff; // matches .clickable style
  --bs-link-underline-opacity: 0; // We don't underline our links here
}

.btn-link {
  // Don't underline link buttons
  text-decoration: none;

  // Have them space just like an a tag
  padding: 0;
  margin: 0;
}
